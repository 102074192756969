<template>

    <div class="w-full h-full px-3 py-4">

        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">

            <div class="h-auto px-2 flex flex-col justify-center items-start mt-6">

                <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('period')}}</span>

                <div class="h-8 w-full">

                    <div class="h-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                    
                        <select name="period" v-model="period" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                            <option v-for="(el,index) in periods" :key="index" :value="el.value" :class="'text-dfont-'+mode">{{el.name}}</option>
                        </select>

                    </div>

                </div>

            </div>

            <filter-months v-if="period == 'month'"   :mode='mode'/>

            <div class="h-10 mt-12 px-12">
                <div class="h-full rounded-lg bg-red flex flex-row justify-center items-center"
                    @click="applyFilters()">
                    <span class="text-lg text-white">{{ $t('apply') }}</span>
                </div>
            </div>

            <div class="h-6"></div>

        </div>

    </div>

</template>

<script>
import { state, actions } from '@/store';

export default {
    data() {
        return {
            options: [this.$t("monthly"), this.$t("quarterly"), this.$t("annual")],
            period: '',
            periods: [
                {
                    name: this.$t("monthly"),
                    value: 'month'
                },
                {
                    name: this.$t("quarterly"),
                    value: 'quarter'
                },
                {
                    name: this.$t("annual"),
                    value: 'year'
                }
            ],
        }
    },
    methods: {
        applyFilters() {
            actions.setPeriod(this.period)
            actions.setFilterKpiComercialSelected(this.period)
            this.$router.go(-1)
        }
    },
    computed:{
        mode(){
            return state.mode
        }
    },
    mounted() {
        this.period = state.period
    }
}
</script>

<style>

    /* .el-input__inner {
        background-color: rgba(0,0,0,0) !important;
        border: 0;
        color: #2C5358;
    } */

</style>
